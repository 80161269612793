import React, { useRef } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { navigate } from "@reach/router"

import { getCxFromStyles } from "../helpers"

import {
  Header,
  AgencyLead,
  AgencyRateDescription,
  AgencyDetails,
  AgencyBenefits,
  Contact,
  Footer,
  CookiesBar,
  OurPartners,
} from "../components"

import * as styles from "./styles.scss"

const Home = () => {
  const contactRef = useRef()
  const cx = getCxFromStyles(styles)

  const goToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const signIn = () => {
    navigate(`${process.env.GATSBY_APP_URL}/register`)
  }

  return (
    <div className={cx("root")}>
      <section className={cx("section", "header")}>
        <Header goToContact={goToContact} signIn={signIn} color="green" />
      </section>
      <section className={cx("section", "lead")}>
        <AgencyLead goToContact={goToContact} />
      </section>
      <section className={cx("section", "did-you-know")}>
        <AgencyRateDescription />
      </section>
      <section className={cx("section", "details")}>
        <AgencyDetails goToContact={goToContact} />
      </section>
      <section className={cx("section", "benefits")}>
        <AgencyBenefits />
      </section>
      <section className={cx("section", "our-partners")}>
        <OurPartners />
      </section>
      <section className={cx("section", "contact")}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
        >
          <Contact ref={contactRef} />
        </GoogleReCaptchaProvider>
      </section>
      <section className={cx("section", "footer")}>
        <Footer />
      </section>
      <CookiesBar />
    </div>
  )
}

export default Home
